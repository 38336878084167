import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from "styled-components"

import Layout from '../components/Layout'
import SEO from '../components/seo'

const Course = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 2rem 2rem 2rem 2rem;
  margin-bottom: 5rem;
  line-height: 1.4;
  background-color: #eee;
  font-size: 1.6rem;
  color: 333;
`

const Grid = styled.div`

`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
`

const List = styled.ul`
  margin: 1rem 0rem 2rem 0rem;
  font-size: 1.4rem;
`

const Anchor = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: #0D5C00;
`

const End = styled.div`
  align-self: flex-end;
  width: 100%;
`


const Intro = styled.div`
    color: #333;
    font-size: 1.8rem;
    grid-column: 1 / 3;
    line-height: 1.3;
    margin-bottom: 4rem;
    padding-right: 4rem;

    @media only screen and (max-width: 768px) {
      grid-column: 1 / 1;
      margin-bottom: 3rem;
    }
`

const Headline = styled.h1`
    font-size: 5rem;
    margin-bottom: 1rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
      margin-top: 1rem;
      font-size: 3rem;
      line-height: 1.3;
    }
`
class MonthlyButton extends React.Component {
  componentDidMount() {
      this.stripe = Stripe('pk_live_W0xMBmT494GQkvMJ46wpCGK500aNqln1rZ');
  }
  render() {
      return(
        <form onSubmit={event => {
            event.preventDefault();
            this.stripe.redirectToCheckout({
              lineItems: [{price: 'price_1HdWl0Jt5j48Yzc1LOICvdM3', quantity: 1}],
              mode: 'subscription',
                  // Do not rely on the redirect to the successUrl for fulfilling
                  // purchases, customers may not always reach the success_url after
                  // a successful payment.
                  // Instead use one of the strategies described in
                  // https://stripe.com/docs/payments/checkout/fulfillment
                  successUrl: window.location.protocol + '//newpragmatic.com/success',
                  cancelUrl: window.location.protocol + '//newpragmatic.com/pricing',
                })
                .then(function (result) {
                  if (result.error) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer.
                    var displayError = document.getElementById('error-message');
                    displayError.textContent = result.error.message;
                  }
                });
              }
          }>
              <button type="submit">$299 Per Month</button>
          </form>
      )
  }
}

class RetainButton extends React.Component {
    componentDidMount() {
        this.stripe = Stripe('pk_live_W0xMBmT494GQkvMJ46wpCGK500aNqln1rZ');
    }
    render() {
        return(
            <form onSubmit={event => {
                event.preventDefault();
                this.stripe.redirectToCheckout({
                    lineItems: [{price: 'price_1HdKeQJt5j48Yzc1yuU2jQQq', quantity: 1}],
                    mode: 'subscription',
                    // Do not rely on the redirect to the successUrl for fulfilling
                    // purchases, customers may not always reach the success_url after
                    // a successful payment.
                    // Instead use one of the strategies described in
                    // https://stripe.com/docs/payments/checkout/fulfillment
                    successUrl: window.location.protocol + '//newpragmatic.com/success',
                    cancelUrl: window.location.protocol + '//newpragmatic.com/pricing',
                  })
                  .then(function (result) {
                    if (result.error) {
                      // If `redirectToCheckout` fails due to a browser or network
                      // error, display the localized error message to your customer.
                      var displayError = document.getElementById('error-message');
                      displayError.textContent = result.error.message;
                    }
                  });
                }
            }>
              <button type="submit">$599 Per Month</button>
            </form>
        )
    }
  }

  class TwoRetainButton extends React.Component {
    componentDidMount() {
        this.stripe = Stripe('pk_live_W0xMBmT494GQkvMJ46wpCGK500aNqln1rZ');    
    }
    render() {
        return(
            <form onSubmit={event => {
                event.preventDefault();
                this.stripe.redirectToCheckout({
                    lineItems: [{price: 'price_1HqZKQJt5j48Yzc1eRDn5cWw', quantity: 1}],
                    mode: 'subscription',
                    // Do not rely on the redirect to the successUrl for fulfilling
                    // purchases, customers may not always reach the success_url after
                    // a successful payment.
                    // Instead use one of the strategies described in
                    // https://stripe.com/docs/payments/checkout/fulfillment
                    successUrl: window.location.protocol + '//newpragmatic.com/success',
                    cancelUrl: window.location.protocol + '//newpragmatic.com/pricing',
                  })
                  .then(function (result) {
                    if (result.error) {
                      // If `redirectToCheckout` fails due to a browser or network
                      // error, display the localized error message to your customer.
                      var displayError = document.getElementById('error-message');
                      displayError.textContent = result.error.message;
                    }
                  });
                }
            }>
                <button type="submit">$1199 Per Month</button>
            </form>
        )
    }
  }



class Pricing extends React.Component {
    render() {
      const { location, title, children } = this.props
      const rootPath = `${__PATH_PREFIX__}/`
      let header
  
      return (
        <Layout>
          <SEO
            title="Courses available"
            keywords={[`curriculum`, `product design`, `UX design`, `user experience`]}
          />
          <Course>
              <div>
                <Title>Monthly Mentorship</Title>
                <p>Self-paced offering with weekly mentor sessions and access to daily feedback for New Pragmatic designers. This monthly subscription includes:</p>
                <List>
                    <li><strong>></strong> Weekly one-hour mentor sessions with Chris Courtney.</li>
                    <li><strong>></strong> Daily feedback on your work.</li>
                    <li><strong>></strong> Guidance through any New Pragmatic course material or individualized support for designers in Career Mode.</li>
                    <li><strong>></strong> Access to weekly workshop sessions.</li>
                    <li><strong>></strong> Access to daily video Feedback Loop review session.</li>
                    <li><strong>></strong> Access to private New Pragmatic community channels.</li>
                </List>
              </div>
              <MonthlyButton />
          </Course>
          <Course>
              <div>
                <Title>Consulting Retainer: Level 1</Title>
                <p>This retainer guarantees you and your organization access to Chris Courney each week. The monthly fee includes:</p>
                <List>
                    <li><strong>></strong> Weekly one-hour consulting sessions focused on the needs of your business or project.</li>
                    <li><strong>></strong> Private feedback on all work performed.</li>
                    <li><strong>></strong> Priority access to Chris for daily discussion.</li>
                    <li><strong>></strong> Ability to add on additional billable hours, invoiced monthly.</li>
                </List>
              </div>
              <RetainButton />
          </Course>
          <Course>
              <div>
                <Title>Consulting Retainer: Level 2</Title>
                <p>This retainer guarantees you and your organization double the access to Chris Courney each week. The monthly fee includes:</p>
                <List>
                    <li><strong>></strong> Two, one-hour consulting sessions each week, focused on the needs of your business or project.</li>
                    <li><strong>></strong> Private feedback on all work performed.</li>
                    <li><strong>></strong> Priority access to Chris for daily discussion.</li>
                    <li><strong>></strong> Ability to add on additional billable hours, invoiced monthly.</li>
                </List>
              </div>
              <TwoRetainButton />
          </Course>
        </Layout>
      )
    }
  }
  
  export default Pricing